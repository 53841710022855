import { Box, styled, Typography } from '@mui/material'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AutoSizer from 'react-virtualized-auto-sizer'
import { VirtuosoHandle } from 'react-virtuoso'
import { ReactComponent as AlertIcon } from 'shared/lib/assets/svg/alert.svg'
import { AlertDialog } from 'shared/lib/components/AlertDialog'
import { ButtonType } from 'shared/lib/components/buttons/ButtonType'
import { HorizontalDivider } from 'shared/lib/components/Divider'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { FullScreenMediaContextProvider } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { Skeleton } from 'shared/lib/components/Skeleton'
import { useTranslation } from 'shared/lib/i18n'
import theme, { errorColor } from 'shared/lib/theme/Theme'
import { TranslationFn } from 'shared/lib/WithTranslations'
import { Comment } from '../comments/Comment'
import {
    CommentListHeaderViewModel,
    CommentListLoadingViewModel,
    NoCommentsListViewModel,
} from '../comments/CommentListViewModels'
import { CommentRow, CommentRowLoading } from '../comments/CommentRow'
import { CreateOrEditCommentView } from '../comments/CreateOrEditCommentView'
import { useComments } from '../comments/hooks'
import { NoCommentsView } from '../comments/NoCommentsView'
import { DetailViewContainer } from '../common/detailView'
import { DETAIL_VIEW_HEADER_BOTTOM_Y } from '../common/detailView/DetailViewHeader'
import { DetailViewProperties } from '../common/detailView/DetailViewProperties'
import { useDetailView } from '../common/detailView/hooks'
import { StyledVirtuoso } from '../common/StyledVirtuoso'
import { MessageView } from '../components/message/MessageView'
import {
    GroupAccessRequestedEvent,
    GroupAccessWithdrawnEvent,
    GroupEventKey,
} from '../groups/events'
import { postRepository, sessionRepository } from '../index'
import { PostCharitiesView } from '../mychurch/components/PostCharitiesView'
import { PostDiscoverGroupsView } from '../mychurch/components/PostDiscoverGroupsView'
import { PostFundraiserView } from '../mychurch/components/PostFundraiserView'
import { PostLikeAndShareRowView } from '../mychurch/components/PostLikeAndShareRowView'
import { PostLinkPreviewView } from '../mychurch/components/PostLinkPreviewView'
import { PostMediaView } from '../mychurch/components/PostMediaView'
import { PostPdfView } from '../mychurch/components/PostPdfView'
import { PostSharedGroupView } from '../mychurch/components/PostSharedGroupView'
import { PostSharedPostView } from '../mychurch/components/PostSharedPostView'
import { PostTopBarLoadingView } from '../mychurch/components/PostTopBarLoadingView'
import { PostTopBarView } from '../mychurch/components/PostTopBarView'
import { Paths } from '../routing/paths'
import { usePost } from './hooks'
import { LikeType } from './likes/LikeType'
import { Post } from './Post'
import { PostContentType } from './PostContentType'
import {
    PostDetailCharitiesViewModel,
    PostDetailCommentViewModel,
    PostDetailDiscoverGroupsViewModel,
    PostDetailDividerViewModel,
    PostDetailFundraiserViewModel,
    PostDetailLikesAndShareViewModel,
    PostDetailLinkPreviewViewModel,
    PostDetailMediaViewModel,
    PostDetailMessageViewModel,
    PostDetailPdfViewModel,
    PostDetailSharedGroupViewModel,
    PostDetailSharedPostViewModel,
    PostDetailViewModel,
} from './PostDetailViewModels'

interface Properties extends DetailViewProperties {
    postId: string
}

export const PostDetailView = ({ postId, onCloseClicked, ...properties }: Properties) => {
    const translations = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const highlightedCommentId = location.state?.highlightedCommentId

    const createOrEditCommentViewRef = useRef<HTMLDivElement>(null)
    const virtuoso = useRef<VirtuosoHandle | null>(null)

    const { isDetailViewDisplayedAsPopup: isDetailViewOpenAsPopup } = useDetailView()
    const { isLoading, error, post, getPost, setPost } = usePost()
    const {
        isLoading: isLoadingComments,
        isUpdatingOrDeleting: isUpdatingOrDeletingComment,
        comments,
        commentToEdit,
        commentIdToDelete,
        indexToScrollTo,
        getComments,
        setCommentToEdit,
        setCommentToDelete,
        deleteComment,
        setIndexToScrollTo,
    } = useComments(postId)

    const [createOrEditCommentViewHeight, setCreateOrEditCommentViewHeight] = useState(0)

    const signedInUser = sessionRepository.signedInUser
    const sharedGroupId = post?.sharedGroup?.id
    const isUserCreatorFromSelectedComment = commentToEdit?.creator?.id === signedInUser?.id

    const onPostLiked = (likeType?: LikeType) =>
        post ? postRepository.likePost(post, likeType).then(setPost) : undefined

    const onPostShared = (post: Post) =>
        navigate(Paths.GROUP_TIME_LINE.replace(':groupId', post.groupId), { replace: true })

    const onSharedGroupUpdated = useCallback(
        (event: Event) => {
            if (
                event instanceof GroupAccessRequestedEvent ||
                event instanceof GroupAccessWithdrawnEvent
            ) {
                const group = event.detail.group

                if (sharedGroupId !== group.id) return

                setPost((prevState) => prevState?.cloneWith({ sharedGroup: group }))
            }
        },
        [sharedGroupId, setPost]
    )

    const onContinueDeleteButtonClicked = () => {
        if (!commentIdToDelete) {
            return
        }

        deleteComment(commentIdToDelete)
    }

    const scrollToIndex = () => {
        if (indexToScrollTo === undefined) {
            return
        }

        virtuoso.current?.scrollToIndex({
            index: indexToScrollTo ?? 0,
            align: 'center',
            behavior: 'smooth',
        })
        setIndexToScrollTo(undefined)
    }

    const viewModels: PostDetailViewModel[] = post
        ? ([
              post.hasMessage ? new PostDetailMessageViewModel() : undefined,
              post.hasMedia ? new PostDetailMediaViewModel() : undefined,
              post.pdfs.length ? new PostDetailPdfViewModel() : undefined,
              post.linkPreviews.length ? new PostDetailLinkPreviewViewModel() : undefined,
              post.hasCharities ? new PostDetailCharitiesViewModel() : undefined,
              post.fundraiser ? new PostDetailFundraiserViewModel() : undefined,
              post.contentType === PostContentType.DISCOVER_GROUPS
                  ? new PostDetailDiscoverGroupsViewModel()
                  : undefined,
              post.hasSharedGroup ? new PostDetailSharedGroupViewModel() : undefined,
              post.hasSharedPost ? new PostDetailSharedPostViewModel() : undefined,
              new PostDetailLikesAndShareViewModel(),
              new PostDetailDividerViewModel(),
              new CommentListHeaderViewModel(comments.length || post.numberOfComments),
              ...(isLoadingComments
                  ? [new CommentListLoadingViewModel()]
                  : comments.length > 0
                    ? comments.map(
                          (comment) =>
                              new PostDetailCommentViewModel(
                                  comment,
                                  comment.id === commentToEdit?.id
                                      ? true
                                      : comment.id === highlightedCommentId
                              )
                      )
                    : [new NoCommentsListViewModel()]),
          ].filter(Boolean) as PostDetailViewModel[])
        : []

    useEffect(() => {
        if (isLoadingComments || !highlightedCommentId) {
            return
        }

        const indexOfComment = viewModels.findIndex((viewModel: PostDetailViewModel) => {
            return (
                viewModel instanceof PostDetailCommentViewModel &&
                viewModel.comment.id === highlightedCommentId
            )
        })

        setTimeout(() => {
            virtuoso.current?.scrollToIndex({
                index: indexOfComment,
                align: 'center',
                behavior: 'smooth',
            })
        }, 100)
    }, [isLoadingComments, highlightedCommentId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const createOrEditCommentView = createOrEditCommentViewRef.current
        if (!createOrEditCommentView) return

        const resizeObserver = new ResizeObserver(() => {
            setCreateOrEditCommentViewHeight(createOrEditCommentView.clientHeight)
        })

        resizeObserver.observe(createOrEditCommentView)
        return () => resizeObserver.disconnect()
    }, [])

    useEffect(() => {
        document.addEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, onSharedGroupUpdated)
        document.addEventListener(GroupEventKey.GROUP_ACCESS_WITHDRAWN, onSharedGroupUpdated)

        return () => {
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_REQUESTED, onSharedGroupUpdated)
            document.removeEventListener(GroupEventKey.GROUP_ACCESS_WITHDRAWN, onSharedGroupUpdated)
        }
    }, [onSharedGroupUpdated])

    useEffect(() => {
        setIndexToScrollTo(undefined)
        setCommentToEdit(undefined)
        setCommentToDelete(undefined)
    }, [post]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getPost(postId)
        getComments()
    }, [postId, getPost, getComments])

    return (
        <>
            <DetailViewContainer
                contentMarginTop={DETAIL_VIEW_HEADER_BOTTOM_Y}
                title={
                    !isLoading && post ? (
                        <PostTopBarView
                            isGroupNameVisible={true}
                            isGroupNameClickable={!post.isInHomeGroup}
                            isCreatorClickable={!post.isInHomeGroup}
                            resetHistoryWhenClicked={false}
                            post={post}
                        />
                    ) : isLoading ? (
                        <PostTopBarLoadingView />
                    ) : undefined
                }
                closeIcon={properties.closeIcon}
                onCloseClicked={onCloseClicked}
            >
                <FullScreenMediaContextProvider>
                    {isLoading ? (
                        <ContentLoadingState />
                    ) : (
                        <AutoSizer>
                            {({ width, height }) => (
                                <StyledVirtuoso
                                    ref={virtuoso}
                                    totalCount={viewModels.length}
                                    itemContent={(index) =>
                                        getRow({
                                            isEditing: !!commentToEdit,
                                            index,
                                            width,
                                            viewModels,
                                            post: post!,
                                            onPostLiked,
                                            onPostShared,
                                            setCommentToEdit,
                                            setCommentToDelete,
                                            setIndexToScrollTo,
                                            translations,
                                        })
                                    }
                                    style={{
                                        height: height - createOrEditCommentViewHeight,
                                        width,
                                        textAlign: 'left',
                                    }}
                                />
                            )}
                        </AutoSizer>
                    )}
                </FullScreenMediaContextProvider>

                <Box position={'absolute'} width={'100%'} bottom={0}>
                    <CreateOrEditCommentView
                        ref={createOrEditCommentViewRef}
                        isUserCreatorFromSelectedComment={isUserCreatorFromSelectedComment}
                        postId={postId}
                        commentToEdit={commentToEdit}
                        onScrollToCommentButtonClicked={scrollToIndex}
                        onCloseButtonClicked={() => setCommentToEdit(undefined)}
                    />
                </Box>
            </DetailViewContainer>

            <AlertDialog
                isLoading={isUpdatingOrDeletingComment}
                isVisible={!!commentIdToDelete}
                titleIcon={<AlertIcon fill={errorColor} />}
                title={translations('remove_comment_message')}
                cancelButtonTitle={translations('cancel')}
                onCancelButtonClicked={() => setCommentToDelete(undefined)}
                continueButtonType={ButtonType.RED}
                continueButtonTitle={translations('remove_comment')}
                onContinueButtonClicked={onContinueDeleteButtonClicked}
            />

            <ErrorHandler
                error={error}
                translations={translations}
                horizontal={isDetailViewOpenAsPopup ? 'center' : 'right'}
            />
        </>
    )
}

const getRow = (params: {
    isEditing: boolean
    index: number
    width: number
    viewModels: PostDetailViewModel[]
    post: Post
    onPostLiked: (likeType?: LikeType) => void
    onPostShared: (post: Post) => void
    setCommentToEdit: (comment: Comment) => void
    setCommentToDelete: (commentId: string) => void
    setIndexToScrollTo: (index: number) => void
    translations: TranslationFn
}) => {
    const viewModel = params.viewModels[params.index]
    const post = params.post
    const isLastItem = params.index === params.viewModels.length - 1

    switch (true) {
        case viewModel instanceof PostDetailMessageViewModel:
            return <MessageView details={post} mx={2} mt={2} />
        case viewModel instanceof PostDetailMediaViewModel:
            return <PostMediaView width={params.width} margins={0} post={post} />
        case viewModel instanceof PostDetailPdfViewModel:
            return <PostPdfView post={post} />
        case viewModel instanceof PostDetailLinkPreviewViewModel:
            return <PostLinkPreviewView post={post} />
        case viewModel instanceof PostDetailCharitiesViewModel:
            return <PostCharitiesView post={post} />
        case viewModel instanceof PostDetailFundraiserViewModel:
            return <PostFundraiserView post={post} />
        case viewModel instanceof PostDetailDiscoverGroupsViewModel:
            return <PostDiscoverGroupsView post={post} />
        case viewModel instanceof PostDetailSharedGroupViewModel:
            return (
                <PostSharedGroupView
                    sharedGroupId={post.sharedGroupId}
                    sharedGroup={post.sharedGroup}
                />
            )
        case viewModel instanceof PostDetailSharedPostViewModel:
            return (
                <PostSharedPostView
                    width={params.width}
                    parentMargins={0}
                    sharedPost={post!.sharedPost}
                    sharedPostId={post!.sharedPostId}
                />
            )
        case viewModel instanceof PostDetailLikesAndShareViewModel:
            return (
                <PostLikeAndShareRowView
                    paddingLeft={1.5}
                    post={post}
                    onPostLiked={params.onPostLiked}
                    onPostShared={(post) => params.onPostShared(post)}
                />
            )
        case viewModel instanceof PostDetailDividerViewModel:
            return <HorizontalDivider mx={2} />
        case viewModel instanceof CommentListHeaderViewModel:
            const numberOfComments = (viewModel as CommentListHeaderViewModel).numberOfComments

            return (
                <CommentsHeader>
                    {params.translations('plural_comments', [numberOfComments], numberOfComments)}
                </CommentsHeader>
            )
        case viewModel instanceof PostDetailCommentViewModel:
            const commentViewModel = viewModel as PostDetailCommentViewModel
            return (
                <CommentRow
                    viewModel={commentViewModel}
                    paddingBottom={isLastItem ? theme.spacing(3) : '0'}
                    onEditButtonClicked={() => {
                        params.setIndexToScrollTo(params.index)
                        params.setCommentToEdit(commentViewModel.comment)
                    }}
                    onDeleteButtonClicked={() =>
                        params.setCommentToDelete(commentViewModel.comment.id)
                    }
                />
            )
        case viewModel instanceof NoCommentsListViewModel:
            return <NoCommentsView />
        case viewModel instanceof CommentListLoadingViewModel:
            return <CommentRowLoading paddingBottom={isLastItem ? theme.spacing(14) : '0'} />
    }
}

const ContentLoadingState = () => (
    <Box px={2}>
        <Skeleton variant="text" width="100%" sx={{ mt: 2 }} />

        <Skeleton variant="rounded" width="100%" height={160} sx={{ mt: 2 }} />
    </Box>
)

const CommentsHeader = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
}))

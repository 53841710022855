import { Box, styled } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as BackIcon } from 'shared/lib/assets/svg/chevron_left.svg'
import { ReactComponent as CloseIcon } from 'shared/lib/assets/svg/close.svg'
import { Popup } from 'shared/lib/components/Popup'
import { useIsMobile } from 'shared/lib/theme/BreakPointHooks'
import { CommentsListView } from '../../comments/CommentsListView'
import { EventDetailEvent } from '../../events/detail/EventDetailView'
import { FeedListView } from '../../feed/feedDetail/FeedListView'
import { GroupDetailView } from '../../groups/GroupDetailView'
import { GroupMembersListView } from '../../groups/members/GroupMembersListView'
import { UnapprovedGroupMembersListView } from '../../groups/members/UnapprovedGroupMembersListView'
import { AllGroupAccessRequestsListView } from '../../notifications/AllGroupAccessRequestsListView'
import { AppAccessRequestsListView } from '../../notifications/AppAccessRequestsListView'
import { FullScreenMediaContextProvider } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { PostDetailView } from '../../posts/PostDetailView'
import { LikesListView } from '../../posts/likes/LikesListView'
import { UserDetailView } from '../../user/userDetail/UserDetailView'
import { ContentContainer } from '../layout/ContentContainer'
import {
    APPLICATION_SEARCH_KEY,
    DISPLAY_TYPE_SEARCH__KEY,
    EVENT_START_KEY,
    SupportedDetailView,
    useDetailView,
} from './hooks'

interface DetailViewViewModel {
    key: SupportedDetailView
    value: string
}

export const DetailView = () => {
    const navigate = useNavigate()

    const isMobile = useIsMobile()
    const { isDetailViewOpen, isDetailViewDisplayedAsPopup } = useDetailView()

    const [searchParams, setSearchParams] = useSearchParams()

    const searchKeysToExclude = useMemo(() => {
        return [APPLICATION_SEARCH_KEY, DISPLAY_TYPE_SEARCH__KEY, EVENT_START_KEY]
    }, [])
    const detailViewModels = useMemo(() => {
        const searchKeys = Array.from(searchParams.keys()).filter(
            (key) => !searchKeysToExclude.includes(key)
        )

        return searchKeys.map((key) => ({
            key: key as SupportedDetailView,
            value: searchParams.get(key)!,
        }))
    }, [searchParams, searchKeysToExclude])

    const onClose = useCallback(() => {
        const searchKeys = Array.from(searchParams.keys()).filter(
            (key) => !searchKeysToExclude.includes(key)
        )
        const lastKey = searchKeys.pop()

        if (!lastKey) {
            return
        }

        if (searchKeys.length === 0) {
            searchKeysToExclude.forEach((key) => searchParams.delete(key))
        }

        searchParams.delete(lastKey)
        setSearchParams(searchParams)

        navigate(
            {
                search: searchParams.toString(),
            },
            {
                replace: true,
            }
        )
    }, [navigate, searchParams, setSearchParams, searchKeysToExclude])

    const contents = useMemo(() => {
        return detailViewModels.map((detailViewModel) => (
            <Content
                detailViewModel={detailViewModel}
                detailViewModels={detailViewModels}
                onClose={onClose}
            />
        ))
    }, [detailViewModels, onClose])

    if (!isDetailViewOpen && !isDetailViewDisplayedAsPopup) {
        return null
    }

    if (isMobile) {
        return (
            <>
                {contents.map((content, index) => (
                    <Popup key={`detail-popup-${index}`} isVisible={true}>
                        <ContentContainer mt={0}>{content}</ContentContainer>
                    </Popup>
                ))}
            </>
        )
    }

    if (isDetailViewDisplayedAsPopup) {
        return (
            <>
                {contents.map((content, index) => (
                    <Popup
                        key={`detail-popup-${index}`}
                        isVisible={true}
                        withBackdrop={index === 0}
                    >
                        <Box display="flex" flexDirection="column" flexGrow={1} overflow="auto">
                            {content}
                        </Box>
                    </Popup>
                ))}
            </>
        )
    }

    return (
        <TabletOrDesktopContainerDetailViewsContainer>
            {contents.map((content, index) => (
                <PhabletTabletOrDesktopContainer key={`detail-container-${index}`} zIndex={index}>
                    {content}
                </PhabletTabletOrDesktopContainer>
            ))}
        </TabletOrDesktopContainerDetailViewsContainer>
    )
}

const Content = (properties: {
    detailViewModel: DetailViewViewModel
    detailViewModels: DetailViewViewModel[]
    onClose: () => void
}) => {
    const [searchParams] = useSearchParams()
    const { openGroupDetailView, openUserDetailView } = useDetailView()

    const { detailViewModel, detailViewModels, onClose } = properties
    const canOpenNewDetailView = detailViewModels.length < 2
    const closeIcon = detailViewModels.length > 1 ? <BackIcon /> : <CloseIcon />

    switch (true) {
        case detailViewModel.key === SupportedDetailView.GROUP:
            return (
                <FullScreenMediaContextProvider>
                    <GroupDetailView
                        groupId={detailViewModel.value}
                        onUserSelected={openUserDetailView}
                        closeIcon={closeIcon}
                        onCloseClicked={onClose}
                        canOpenNewDetailView={canOpenNewDetailView}
                    />
                </FullScreenMediaContextProvider>
            )
        case detailViewModel.key === SupportedDetailView.USER:
            return (
                <FullScreenMediaContextProvider>
                    <UserDetailView
                        userId={detailViewModel.value}
                        onGroupSelected={openGroupDetailView}
                        closeIcon={closeIcon}
                        onCloseClicked={onClose}
                        canOpenNewDetailView={canOpenNewDetailView}
                    />
                </FullScreenMediaContextProvider>
            )
        case detailViewModel.key === SupportedDetailView.GROUP_MEMBERS:
            return (
                <GroupMembersListView
                    groupId={searchParams.get(SupportedDetailView.GROUP)!}
                    closeIcon={closeIcon}
                    onCloseClicked={onClose}
                />
            )
        case detailViewModel.key === SupportedDetailView.POST:
            return (
                <PostDetailView
                    postId={detailViewModel.value}
                    closeIcon={closeIcon}
                    onCloseClicked={onClose}
                />
            )
        case detailViewModel.key === SupportedDetailView.COMMENTS:
            return (
                <CommentsListView
                    postId={detailViewModel.value}
                    closeIcon={closeIcon}
                    onCloseClicked={onClose}
                />
            )
        case detailViewModel.key === SupportedDetailView.LIKES:
            return (
                <LikesListView
                    postId={detailViewModel.value}
                    closeIcon={closeIcon}
                    onCloseClicked={onClose}
                />
            )
        case detailViewModel.key === SupportedDetailView.FEED:
            return (
                <FeedListView
                    feedId={detailViewModel.value}
                    closeIcon={closeIcon}
                    onCloseClicked={onClose}
                />
            )
        case detailViewModel.key === SupportedDetailView.GROUP_ACCESS_REQUESTS:
            return detailViewModel.value === 'all' ? (
                <AllGroupAccessRequestsListView closeIcon={closeIcon} onCloseClicked={onClose} />
            ) : (
                <UnapprovedGroupMembersListView
                    groupId={detailViewModel.value}
                    closeIcon={closeIcon}
                    onCloseClicked={onClose}
                />
            )
        case detailViewModel.key === SupportedDetailView.EVENT:
            return <EventDetailEvent closeIcon={closeIcon} onCloseClicked={onClose} />
        case detailViewModel.key === SupportedDetailView.APP_ACCESS_REQUESTS:
            return <AppAccessRequestsListView closeIcon={closeIcon} onCloseClicked={onClose} />
        default:
            return null
    }
}

const TabletOrDesktopContainerDetailViewsContainer = styled(Box)(() => ({
    position: 'relative',
    height: `100%`,
    width: '100%',
}))

const PhabletTabletOrDesktopContainer = styled(Box)(() => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',

    '&>:first-of-type': {
        height: `100%`,
    },
}))
